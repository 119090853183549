import { Loading } from "@appkit4/react-components";
import React, { Suspense } from "react";

// This HOC adds a Suspense wrapper around any component
// that is lazily imported and needs a suspense-fallback logic.
// This can be later modified to work with different dataset and
// custom fallbacks
// Refer: https://reactjs.org/docs/higher-order-components.html

export default function WithSuspense(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                props: props
            };
        }

        componentDidMount() {
            // add subscription/ handler
        }

        componentWillUnmount() {
            // remove subscription/ handler
        }

        render() {
            return (
                <Suspense
                    fallback={<Loading loadingType='circular' indeterminate={false} compact={false} />}>
                    <WrappedComponent {...this.props} />
                </Suspense>
            );
        }
    };
}