import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const connectionString = `InstrumentationKey=${process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_APPINSIGHTS_INGESTION_ENDPOINT};LiveEndpoint=${process.env.REACT_APP_APPINSIGHTS_LIVE_ENDPOINT}`;
const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
