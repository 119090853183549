import { useState, createContext } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  /*
    User state to be updated with values to prepopulate customer details form
    and generate receipt.
  */
  const [userInformation, setUserInformation] = useState({
    dob: "",
    agreementStart: "",
    agreementEnd: "",
    monthlyRentalAmount: "",
    termsOfAgreement: "",
    firstName: "",
    lastName: "",
    fullName: "",
    addressLineOne: "",
    addressLineTwo: "",
    town: "",
    postCode: "",
    country: "",
    bankSortCode: "",
    bankAccount: "",
    customerId: "",
    carRegistration: "",
    date: "",
    errorMessage: "",
    statusCode: ""
  });

  return (
    <UserContext.Provider value={{ userInformation, setUserInformation }}>
      {children}
    </UserContext.Provider>
  );
};
