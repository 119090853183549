import React, { lazy } from "react";
import { UserContextProvider } from "./Shared/Contexts/UserContext";
import { createHashRouter, RouterProvider} from "react-router-dom";
import WithSuspense from "./Shared/Components/WithSuspense";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './ApplicationInsightsService';

function App() {
  const Home = WithSuspense(lazy(() => import("./App/Home")));
  const CustomerDetailsForm = WithSuspense(
    lazy(() => import("./App/CustomerDetailsForm"))
  );
  const IDVForm = WithSuspense(lazy(() => import("./App/IDVForm")));
  const FAQPage = WithSuspense(lazy(() => import("./App/FAQPage")));
  const SuccessPage = WithSuspense(lazy(() => import("./App/Success")));
  const FailurePage = WithSuspense(lazy(() => import("./App/Failure")));
  const Layout = WithSuspense(lazy(() => import("./App/Layout")));

  const AppRoutes = createHashRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "customer-details-form",
          element: <CustomerDetailsForm />,
        },
        {
          path: "idv-form",
          element: <IDVForm />,
        },
        {
          path: "faq-page",
          element: <FAQPage />,
        },
        {
          path: "success",
          element: <SuccessPage />,
        },
        {
          path: "error",
          element: <FailurePage />,
        },
      ],
    },
  ]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <UserContextProvider>
        <RouterProvider router={AppRoutes} />
      </UserContextProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
